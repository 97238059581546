<template>
  <v-card
    @mouseover="hover = true"
    @mouseleave="hover = false"
    :elevation="hover ? 24 : 1"
    hover
    width="400"
    :max-width="getCardWith"
    max-height="500"
    @click="openVehicle(vehiculo)"
    class="rounded-lg"
    :class="{
      'mt-12': margin && $vuetify.breakpoint.mdAndUp,
      'mb-12': margin,
    }"
  >
    <v-img
      :src="validateImageLarge(vehiculo.foto)"
      :lazy-src="validateImageSmall(vehiculo.foto)"
      :max-width="getCardWith"
      height="300"
      max-height="300"
      contain
      class="align-start text-start"
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0"
          align="center"
          justify="center"
          no-gutters
        >
          <v-progress-circular indeterminate color="info"></v-progress-circular>
        </v-row>
      </template>

      <v-chip :color="vehicleStatus.color" small class="white--text mt-1 ml-1"
        >{{ vehicleStatus.status }}
      </v-chip>
    </v-img>
    <v-col cols="12" class="pa-0">
      <v-row
        align="center"
        justify="center"
        no-gutters
        style="background-color: #0434ac"
      >
        <h2 class="white--text">{{ vehiculo.titulo }}</h2>
      </v-row>
    </v-col>
    <v-card-text class="ml-0 mr-0 pa-0" style="background-color: white">
      <v-row align="center" justify="center" no-gutters class="mt-3 mb-1">
        <h1 class="body-1 info--text text-center">
          {{ vehiculo.version }}
        </h1>
      </v-row>
      <v-row align="center" justify="center" no-gutters class="mt-1 mb-1">
        <h1 class="body-1 info--text text-center">
          {{ vehiculo.subtitulo }}
        </h1>
      </v-row>
      <v-row v-if="detail" align="center" justify="center" no-gutters>
        <h4 class="info--text body-1">
          {{ kilometrajeFix(vehiculo.kilometraje) }} Kms
        </h4>
      </v-row>
      <v-row align="center" justify="center" no-gutters>
        <h3 class="info--text">Precio {{ precioFix(vehiculo.precio) }}</h3>
      </v-row>
      <v-row
        align="center"
        justify="center"
        no-gutters
        @click.stop
        class="mt-2 pb-2"
      >
        <v-menu :nudge-left="80" offset-y top open-on-hover>
          <template v-slot:activator="{on, attrs}">
            <v-btn
              color="info"
              :disabled="availableStatus(vehicleStatus.status)"
              :small="$vuetify.breakpoint.smAndDown"
              v-bind="attrs"
              v-on="on"
            >
              Me Interesa
            </v-btn>
          </template>

          <v-card class="pa-2" width="300" color="white">
            <v-btn
              color="info"
              :disabled="availableStatus(vehicleStatus.status)"
              :small="$vuetify.breakpoint.smAndDown"
              block
              @click="openContact(vehiculo)"
              class="py-1 my-1"
            >
              Que me contacten
            </v-btn>
            <v-btn
              color="info"
              :disabled="availableStatus(vehicleStatus.status)"
              :small="$vuetify.breakpoint.smAndDown"
              block
              class="py-1 my-1"
              @click="openFinancials(vehiculo)"
            >
              Financiamiento
            </v-btn>
            <v-btn
              color="info"
              :disabled="availableStatus(vehicleStatus.status)"
              :small="$vuetify.breakpoint.smAndDown"
              block
              class="py-1 my-1"
              @click="openRenew(vehiculo)"
            >
              Renovación
            </v-btn>
          </v-card>
        </v-menu>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import {
  kilometrajeFix,
  precioFix,
  validateImageLarge,
  validateImageSmall,
} from '@/utils/vehiculo';
import {mapMutations, mapState} from 'vuex';
import {VChip} from 'vuetify/lib';

export default {
  name: 'vehiculoDisplay',
  components: {VChip},
  props: {
    value: {
      type: Object,
    },
    vehiculo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    margin: {
      type: Boolean,
      default: true,
    },
    detail: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    hover: false,
    open: false,
  }),
  mounted() {
    this.setParams(this.$route.query);
  },
  computed: {
    ...mapState([
      'loading',
      'stockGeneral',
      'canal',
      'facebookId',
      'instagramId',
      'webPageId',
      'campaign',
    ]),
    getCardWith() {
      const {
        $vuetify: {
          breakpoint: {xlOnly, lgOnly, mdOnly, smOnly},
        },
      } = this;

      if (xlOnly) return 450;
      if (lgOnly || mdOnly) return 460;
      if (smOnly) return 400;
      return 390;
    },
    vehicleStatus() {
      const {vehiculo} = this;

      const color = this.getColor(vehiculo.status);

      return {
        status: vehiculo.status,
        color,
      };
    },
  },
  methods: {
    ...mapMutations(['setCanal', 'SET_BUY_VEHICLE']),
    openVehicle(vehiculo) {
      this.$router.push({
        name: 'resultado-vehiculo',
        params: {
          vehiculoId: vehiculo.id,
        },
      });
    },
    availableStatus(status) {
      return status === 'RESERVADO' || status === 'VENTA EN PROCESO';
    },
    setParams(query) {
      if (!query || !(Object.keys(query).length > 0)) {
        return;
      }

      const {canal, campaign} = query;

      this.setCanal({canal, campaign});
    },
    kilometrajeFix,
    precioFix,
    validateImageLarge,
    validateImageSmall,
    message(val) {
      let item = `https://api.whatsapp.com/send?phone=+56944436444&text=Hola%20Petersen!,%20vi%20en%20su%20página%20web%20el%20${val.titulo}%20${val.version},%20a%C3%B1o%20${val.year},%20patente%20${val.ppu},%20y%20tengo%20algunas%20consultas`;
      window.open(item, '_blank');
    },
    urlGenerator(auto) {
      const vehicle = this.stockGeneral.find((stock) => stock.id === auto.id);

      const web = 'https://www.chileautos.cl';

      const url = `${web}/vehiculos/${vehicle.datos.Marca.toLowerCase()}/${vehicle.datos.Modelo.toLowerCase()}/${
        vehicle.datos.Combustible
      }-combustible/${vehicle.datos.Ano}-ano/${
        vehicle.datos.Transmision
      }-transmisión/menos-de-100000km-kilometraje/metropolitana-de-santiago-región/?sort=~Price`;

      window.open(url.replace(' ', '%20'), '_blank');
    },
    fixSubtitle(val) {
      if (this.$vuetify.breakpoint.xs) {
        if (val.length < 15) return val;
        return val.substring(0, 15) + '...';
      } else if (this.$vuetify.breakpoint.sm) {
        if (val.length < 18) return val;
        return val.substring(0, 18) + '...';
      } else if (this.$vuetify.breakpoint.md) {
        if (val.length < 23) return val;
        return val.substring(0, 23) + '...';
      }

      if (val.length < 28) return val;
      return val.substring(0, 28) + '...';
    },
    openFinancials(vehiculo) {
      this.$router.push({
        name: 'financiamiento',
        query: {vehicle: vehiculo.id},
      });
    },
    openRenew(vehiculo) {
      this.$router.push({
        name: 'renovacion',
        query: {vehicle: vehiculo.id},
      });
    },
    getColor(icon) {
      if (icon.toLowerCase().includes('reservado')) {
        return 'purple';
      }

      if (icon.toLowerCase().includes('venta')) {
        return 'info';
      }
      if (icon.toLowerCase().includes('vendido')) {
        return '#6185DB';
      }

      return 'green';
    },
    openContact(vehiculo) {
      this.$router.push({
        name: 'contacto',
        query: {vehicle: vehiculo.id},
      });
    },
  },
};
</script>

<style scoped></style>
